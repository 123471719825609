import { Navigate, useParams } from "react-router-dom";
import cookie from 'react-cookies';

const RequireAuth = ({ children }) => {
	const auth = cookie.load('SESSION');
	let { device, deviceType } = useParams();

	if (!auth) {
		let url = '/login';
		if (device !== undefined && deviceType !== undefined) {
			url += '/' + device + '/' + deviceType;
		}

		return <Navigate to={url} replace />;
	}

	return children;
}

export default RequireAuth;