import * as React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import RequireAuth from 'helpers/RequireAuth';
import Login from 'routes/Login';
import ForgotPassword from 'routes/ForgotPassword';
import Onboard from 'routes/Onboard';

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<RequireAuth><Login /></RequireAuth>}></Route>
				<Route path="/login" element={<Login />}>
					<Route path=":device/:deviceType" element={<Login />}></Route>
				</Route>
				<Route path="/password/reset/:lang">
					<Route path=":token" element={<ForgotPassword />} />
					<Route path="" element={<ForgotPassword />} />
				</Route>
				{/* <Route path="/password/confirm/:lang/:token" element={<ConfirmUser />}></Route> */}
				<Route path="/onboard/:device/:deviceType" element={<RequireAuth><Onboard /></RequireAuth>} />
			</Routes>
		</BrowserRouter>
	);
}

export default App;

