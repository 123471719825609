import { create } from 'apisauce';
import cookie from 'react-cookies';

let backendHost = 'https://services.senti.cloud/core';
const hostname = window && window.location && window.location.hostname;
let deviceConfigHost = 'https://services.senti.cloud/device-configuration';
if (hostname === 'localhost') {
	deviceConfigHost = 'http://localhost:3057';
}

export const coreApi = create({
	baseURL: backendHost,
	timout: 30000,
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	}
});

export const deviceConfigApi = create({
	baseURL: deviceConfigHost,
	timeout: 30000,
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json',
	}
});

export const setToken = () => {
	try {
		let token = cookie.load('SESSION').token;
		coreApi.setHeader('Authorization', 'Bearer ' + token);
		deviceConfigApi.setHeader('Authorization', 'Bearer ' + token);

		return true;
	} catch (error) {
		return false;
	}
};

export const loginUser = async (username, password) => {
	const session = await coreApi.post('/v2/auth/basic', JSON.stringify({ username: username, password: password })).then(rs => rs.data);
	return session;
}

export const resetPassword = async (email) => {
	let status = await coreApi.post('/v2/entity/user/forgotpassword', { email: email }).then(rs => rs.status);
	return status;
}

export const confirmPassword = async (obj) => {
	let status = await coreApi.post('/v2/entity/user/forgotpassword/set', obj).then(rs => rs.status);
	return status;
}

export const getConfigSite = async () => {
	let status = await deviceConfigApi.get('/site').then(rs => rs.data);
	return status;
}

setToken();
