import React, { useEffect, useState } from 'react';
import { Button, Container, Paper, Typography } from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import cookie from 'react-cookies';

import mainStyles from 'styles/mainStyles';
import CircularLoader from 'components/CircularLoader';
import { getConfigSite } from '../data/api';

const Onboard = () => {
	let { device, deviceType } = useParams();
	const navigate = useNavigate();

	const [error, setError] = useState(null);

	const classes = mainStyles();

	useEffect(() => {
		async function fetchData() {
			const auth = cookie.load('SESSION');

			if (auth) {
				const siteData = await getConfigSite();

				if (!siteData || !siteData.url?.length) {
					setError(true);
				} else {
					window.location.replace(siteData.url + '/auth/' + device + '/' + deviceType + '/' + auth.token);
				}
			} else {
				navigate('/login/' + device + '/' + deviceType, { replace: true });
			}
		}

		fetchData();
	}, [device, deviceType, navigate]);

	return (
		<Container component="main" maxWidth="sm">
			<div className={classes.logoWrapper}>
				<img src='/senti_logo.svg' alt="Senti" className={classes.logoImage} />
			</div>

			<Paper elevation={3} className={classes.loginPaper}>
				{!error ?
					<CircularLoader />
					:
					<>
						<Typography color="error">Kunne ikke finde konfigurations side</Typography>
						<Button variant="contained" onClick={() => navigate('/login/' + device + '/' + deviceType)} style={{ marginTop: 30 }}>Log ud</Button>
					</>
				}
			</Paper>
		</Container>
	)
}

export default Onboard;