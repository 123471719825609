import { makeStyles } from '@mui/styles';

const mainStyles = makeStyles(theme => ({
	logoWrapper: {
		textAlign: 'center',
		marginTop: '30px',
		marginBottom: '30px',
	},
	logoImage: {
		maxWidth: '70%',
	},
	loginPaper: {
		textAlign: 'center',
		padding: '40px',
	},
	loginHeader: {
		fontSize: '30px !important',
		fontWeight: '400 !important',
		[theme.breakpoints.down('sm')]: {
			fontSize: '20px !important',
		}
	},
	textField: {
		width: '100%',
		// height: '38px',
		marginTop: '20px !important',
		backgroundColor: '#FAFAFB',
	},
	loginButton: {
		width: '100%',
		height: '38px',
		backgroundColor: '#FF7017 !important',
		textTransform: 'uppercase',
		marginTop: '20px !important',
	},
	linksWrapper: {
		marginTop: '30px',
	},
	bottomWrapper: {
		marginTop: '20px',
		textAlign: 'center',
		paddingLeft: '30px',
		paddingRight: '30px',
	},
	copyrightText: {
		color: '#fff',
	},
	bottomText: {
		color: '#fff',
	},
	bottomText2: {
		color: '#fff',
		paddingTop: '40px',
	},
	bottomStickyWrapper: {
		width: '100%',
		height: '40px',
		paddingLeft: '40px',
		paddingRight: '40px',
	},
}));

export default mainStyles;