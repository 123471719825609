import { createTheme } from "@mui/material/styles";

const theme = createTheme({
	palette: {
		background: {
			default: '#161438',
		},
	}
});

export default theme;